@import url(https://unpkg.com/leaflet@1.5.1/dist/leaflet.css);
@page {
  size: landscape !important;
  margin: 20mm;
}

@media print {
  html, body {
    height: auto !important;
    height: initial !important;
    overflow: visible !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    page-break-inside: avoid;
    display: table;
  }
}

@media screen {
  .PrintableComponent-wrapper {
    display: -webkit-flex;
    display: flex;
  }
  .PrintableComponent-wrapper .PrintableComponent {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .PrintableComponent {
    display: none !important;
  }
}

@media print {
  @page {
    size: landscape !important;
    margin: 4mm;
  }
  html, body, page {
    margin: 0;
    box-shadow: 0;
    overflow: visible !important;
    -webkit-print-color-adjust: exact;
    page-break-inside: avoid;
  }
  .PrintableComponent {
    display: inline;
  }
 
  .page {
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  table {
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

body, html {
  height: 100%;
}
#root {
  height: 100%;
  display: -webkit-flex;
  display: flex;
}
