@page {
  size: landscape !important;
  margin: 20mm;
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    page-break-inside: avoid;
    display: table;
  }
}
