@media screen {
  .PrintableComponent-wrapper {
    display: flex;
  }
  .PrintableComponent-wrapper .PrintableComponent {
    display: flex !important;
  }
  .PrintableComponent {
    display: none !important;
  }
}

@media print {
  @page {
    size: landscape !important;
    margin: 4mm;
  }
  html, body, page {
    margin: 0;
    box-shadow: 0;
    overflow: visible !important;
    -webkit-print-color-adjust: exact;
    page-break-inside: avoid;
  }
  .PrintableComponent {
    display: inline;
  }
 
  .page {
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  table {
    page-break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}
